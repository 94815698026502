.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.width-90 {
  width: 90%;
}

.results-box {
    min-height: 96px;
}

@media only screen and (max-width: 576px) {
  h1.button {
    font-size: large;
    margin-bottom: 0;
  }
  .result-txt {
    font-size: x-large;
    margin-bottom: 0;
  }
  .results-box {
    min-height: 68px;
  }
}